<template>
  <section class="all-documents-view">
    <div class="vue-data-table-default">
      <div>
        <el-table
          v-loading="configureLoading"
          :element-loading-text="
            is_documentDelete || isDocumentDelete
              ? 'Deleting Document'
              : sendingMail
              ? 'Sending Document'
              : 'Documents Loading...'
          "
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          :data="documents"
          style="width: 100%"
          @sort-change="sortChange"
          v-if="!getIsMobile"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            v-if="this.$route && this.$route.name != 'Dashboard'"
          >
          </el-table-column>
          <el-table-column
            label="Name"
            id="name"
            prop="title"
            min-width="230"
            fixed="left"
            sortable="custom"
          >
            <template slot-scope="scope">
              <div class="icon-block">
                <div class="icon">
                  <div class="image-file">
                    <img
                      src="@/assets/img/icons/image-file.svg"
                      alt="icon"
                      class="img-fluid"
                    />
                  </div>
                </div>
                <div class="icon-text fs-8">
                  <!-- <template v-if="scope.row.title">{{
                  scope.row.title
                }}</template>-->
                  <template v-if="scope.row.title">
                    <el-popover
                      placement="top-start"
                      width="200"
                      trigger="hover"
                      :content="scope.row.title"
                    >
                      <a
                        slot="reference"
                        class="doc-title"
                        @click="onEdit(scope.row)"
                      >
                        {{ getDocumentTitle(scope.row.title) }}
                      </a>
                    </el-popover>
                  </template>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="action"
            class="action"
            min-width="185"
            fixed="left"
          >
            <template slot-scope="scope">
              <ul class="action-buttons hover-buttons">
                <el-tooltip content="View Document">
                  <li>
                    <el-button
                      plain
                      type="danger"
                      size="mini"
                      @click="onEdit(scope.row)"
                    >
                      <i class="el-icon-view"></i>
                    </el-button></li
                ></el-tooltip>
                <li style="margin-left: -20px">
                  <el-dropdown>
                    <el-button
                      style="margin-right: 10px"
                      size="mini"
                      class="el-dropdown-link"
                    >
                      Actions
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <a @click="onDownload(scope.row)">
                        <el-tooltip
                          content="You can download the original document uploaded by 'Document Admin'"
                          placement="left-end"
                        >
                          <el-dropdown-item
                            ><i class="el-icon-download"></i>&nbsp;Download
                            Extracted Data</el-dropdown-item
                          >
                        </el-tooltip>
                      </a>

                      <a
                        @click="updateDocumentStatus(scope.row._id, 'INACTIVE')"
                        v-if="scope.row.document_status === 'ACTIVE'"
                      >
                        <el-dropdown-item
                          ><i class="el-icon-circle-close"></i>&nbsp;Inactive
                        </el-dropdown-item>
                      </a>
                      <a
                        @click="updateDocumentStatus(scope.row._id, 'ACTIVE')"
                        v-if="scope.row.document_status === 'INACTIVE'"
                      >
                        <el-dropdown-item
                          ><i class="el-icon-circle-close"></i>&nbsp;Active
                        </el-dropdown-item>
                      </a>

                      <a @click="archiveDocument(scope.row, scope.row._id)">
                        <el-dropdown-item
                          ><i class="el-icon-delete-solid"></i
                          >&nbsp;Delete</el-dropdown-item
                        >
                      </a>
                    </el-dropdown-menu>
                  </el-dropdown>
                </li>
              </ul>
            </template>
          </el-table-column>
          <el-table-column
            label="Status"
            id="category"
            prop="document_status"
            min-width="150"
            sortable="custom"
          >
            <template slot-scope="scope" id="category">
              <div class="check-font">
                <span
                  :class="scope.row.document_status.toLowerCase() + ' circle'"
                >
                  {{ scope.row.document_status }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Created at"
            width="300"
            prop="created_at"
            sortable="custom"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.created_at">
                <span class="d-inline-block pr-2 text-muted">
                  {{ scope.row.created_at | globalDateTimeFormat }}
                </span>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <el-table
          v-loading="configureLoading"
          element-loading-text="Documents Loading..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          :data="documents"
          style="width: 100%"
          v-if="getIsMobile"
        >
          <el-table-column type="expand">
            <template slot-scope="scope">
              <div class="expand-column-item">
                <div class="expand-column-item-label">Status:</div>
                <div class="expand-column-item-content">
                  <span
                    :class="scope.row.document_status.toLowerCase() + ' circle'"
                    >{{ scope.row.document_status }}</span
                  >
                </div>
              </div>
              <div class="expand-column-item">
                <div class="expand-column-item-label">Created at:</div>
                <div class="expand-column-item-content">
                  <template v-if="scope.row.created_at">
                    <span class="fs-8 d-inline-block pr-2 text-muted">
                      {{ scope.row.created_at | globalDateTimeFormat }}
                    </span>
                  </template>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Name"
            id="name"
            min-width="150"
            sortable="custom"
            fixed
          >
            <template slot-scope="scope">
              <div class="icon-block">
                <div class="icon">
                  <div class="image-file">
                    <img
                      src="@/assets/img/icons/image-file.svg"
                      alt="icon"
                      class="img-fluid"
                    />
                  </div>
                </div>
                <div class="icon-text fs-8">
                  <!-- <template v-if="scope.row.title">{{
                    scope.row.title
                  }}</template>-->
                  <template v-if="scope.row.title">
                    <a class="doc-title" @click="onEdit(scope.row)">
                      {{ getDocumentTitle(scope.row.title) }}
                    </a>
                  </template>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="action"
            class="action"
            min-width="135"
            fixed="left"
          >
            <template slot-scope="scope">
              <ul class="action-buttons hover-buttons">
                <div style="display: flex">
                  <div>
                    <el-tooltip content="View Document">
                      <li style="margin-right: -10px">
                        <el-button
                          plain
                          type="danger"
                          size="mini"
                          class="px-2"
                          @click="onEdit(scope.row)"
                        >
                          <i class="el-icon-view"></i>
                        </el-button></li
                    ></el-tooltip>
                  </div>
                  <div>
                    <li>
                      <el-dropdown>
                        <el-button
                          style="margin-right: 10px"
                          size="mini"
                          class="el-dropdown-link"
                        >
                          Actions
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                          <a @click="onDownload(scope.row)">
                            <el-dropdown-item
                              ><i class="el-icon-download"></i>Download
                              Extracted Data</el-dropdown-item
                            >
                          </a>
                          <a
                            @click="
                              updateDocumentStatus(scope.row._id, 'INACTIVE')
                            "
                            v-if="scope.row.document_status === 'ACTIVE'"
                          >
                            <el-dropdown-item
                              ><i class="el-icon-circle-close"></i
                              >Inactive</el-dropdown-item
                            >
                          </a>
                          <a
                            @click="
                              updateDocumentStatus(scope.row._id, 'ACTIVE')
                            "
                            v-if="scope.row.document_status === 'INACTIVE'"
                          >
                            <el-dropdown-item
                              ><i class="el-icon-circle-close"></i
                              >Active</el-dropdown-item
                            >
                          </a>
                          <a @click="archiveDocument(scope.row._id)">
                            <el-dropdown-item
                              ><i class="el-icon-delete"></i
                              >Delete</el-dropdown-item
                            >
                          </a>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </li>
                  </div>
                </div>
              </ul>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        class="custom-pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="paginationProps"
        :page-size="pageSize"
        layout="prev, pager, next, jumper, sizes, total"
        :total="total"
      ></el-pagination>

      <el-dialog
        :title="modalData.action + ' Document'"
        :visible.sync="documentActionModal"
        :width="getIsMobile ? '90%' : '30%'"
        class="doc-modal"
        v-loading="modalLoading"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <span>
          <label>Document Title :</label>
          <el-input
            type="text"
            placeholder="Document Name"
            v-model="modalData.name"
          ></el-input>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="documentActionModal = false">Cancel</el-button>
          <el-button
            type="primary"
            @click="handleDocAction"
            :disabled="modalData.name.length == 0"
            >Confirm</el-button
          >
        </span>
      </el-dialog>
    </div>
  </section>
</template>
<script>
import { bus } from "../../main";
import { mapGetters } from "vuex";
// import store from "@/store";
// import moment from "moment";
import axios from "@/config/axios";
import appConfig from "@/config/app";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications.js";
// const ConfigureSettings = () =>
//   import(
//     "@/components/requestrequest-Documents/configurerequest-Documents/ConfigureSettingsNewTemp"
//   );
// const ConfigureDocumentUsers = () =>
//   import(
//     "@/components/requestrequest-Documents/configurerequest-Documents/ConfigureDocumentUsers"
//   );
import PermissionsHelper from "@/mixins/permissionsHelper";
export default {
  name: "requestDocuments-DocumentsList",
  components: {
    // ConfigureDocumentUsers,
    // ConfigureSettings,
  },
  props: [
    "page_size",
    "selectedDocStatus",
    "search_string",
    "search_by",
    "selected_company_user",
  ],
  mixins: [PermissionsHelper],
  data() {
    return {
      isValid: true,
      sendingMail: false,
      allContacts: [],
      selectedMail: [],
      isSendToMail: false,
      ResendDocumentToRecipient: false,
      is_documentDelete: false,
      selected_document: [],
      resendDocumentUsers: [],
      resendEmployeeDocumentId: null,
      addDocumentRecipient: false,
      docSettingsVisible: false,
      loading: false,
      outerVisible: false,
      editDailogVisible: false,
      selectedRequestDocument: "",
      total: 0,
      currentPage: 1,
      pageSize: 10,
      page: 1,
      order_type: null,
      order_by: null,
      configureLoading: false,
      openSendDocumentsModal: false,
      documentUsers: [],
      showSendDocumentoUserSettings: false,
      enforceSignatureOrder: null,
      documents: [],
      activeName: "contacts",
      modalData: {
        document_id: "",
        name: "",
        action: "",
      },
      documentActionModal: false,
      modalLoading: false,
      authid: "",
      tagType: ["success", "info", "", "warning", "danger"],
      limit: 2,
      nodoc: [],
      nonsig: [],
      selectedUsers: [],
      contactUsers: [],
      templateShareModal: false,
      selectedDocumentUsers: [],
      selectedDocumentId: null,
      selectedContactTypes: [],
      contactTypes: [],
      searchedContact: "",
      searchedContacts: [],
      searchedContactTypes: [],
      selectAllContacts: false,
      selectAllContactTypes: false,
      isDocumentDelete: "",
      subjectName: "",
      form: {
        emailSubject: this.subjectName,
        emailBody:
          "Please find signed document attached to this mail. Anticipating your action at the earliest.  ",
        documentId: "",
      },
    };
  },
  created() {
    bus.$on("documentLoading", (data) => {
      this.isDocumentDelete = data;
      this.loadingDocument();
    });
  },
  computed: {
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getUserType",
    ]),
    ...mapGetters("requestDocuments", [
      "getAllDocuments",
      "getRenameDocumentStatus",
      "getDuplicateDocumentStatus",
      "getArchiveDocumentStatus",
      "getDocumentVoidStatus",
      "getResendDocumentStatus",
      "getDocumentSaveAsTemplate",
      "getDocumentCorrectStatus",
      "getDocumentCorrectErrors",
      "getCloneVoidDocStatus",
      "getCloneVoidDocData",
      "getUpdateDocumentUsersStatus",
      "getDocumentSaveAsTemplateData",
      "getSendCompletedDocumentStatus",
    ]),
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("configurableDocuments", ["getConfigurableDocumentDataById"]),
    ...mapGetters("contacts", ["getContactUser", "getAllContacts"]),
    ...mapGetters("contactTypes", ["getAllContactTypes"]),
    paginationProps() {
      return [5, 10, 20, 50];
    },
    basePdfdownloadUrl() {
      // return appConfig.JAVA_BASE_URL
      return appConfig.S3_BUCKET_URL;
    },
  },
  async mounted() {
    console.log("mounted check", this.getActiveWorkspace);
    this.pageSize = this.page_size ? parseInt(this.page_size) : 10;
    // this.outerVisible = true;
    await this.fetchRequestDocuments();
    // await this.fetchContactTypes();
  },
  methods: {
    updateSettings() {
      this.docSettingsVisible = false;
      this.fetchRequestDocuments();
    },
    validateMails() {
      let re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let filteredMail = [];
      filteredMail = this.selectedMail.filter((e) => {
        if (re.test(e)) {
          return e;
        } else {
          this.isValid = false;
        }
      });
      if (!this.isValid) {
        //  this.$message({
        //   message: 'Please Enter a valid email',
        //   type: 'warning'
        // });
        this.selectedMail = filteredMail;
      }
    },
    loadingDocument() {
      this.configureLoading = true;
    },
    handleSelectionChange(val) {
      this.selected_document = [];
      this.selected_document = val;
    },
    async gotoMainDocument(data) {
      if (data && data.document_completed_url) {
        let url = this.basePdfdownloadUrl + data.document_completed_url;
        axios.defaults.headers.common = {
          "Access-Control-Allow-Origin": window.location.origin,
        };
        await fetch(url, { method: "get", responseType: "blob" })
          .then((res) => res.blob()) // Gets the response and returns it as a blob
          .then((blob) => {
            var fileURL = window.URL.createObjectURL(new Blob([blob]));
            var fileLink = document.createElement("a");
            let file_name = data.title.split(".")[0];
            fileLink.href = fileURL;
            fileLink.setAttribute("download", file_name + ".pdf");
            document.body.appendChild(fileLink);

            fileLink.click();
          });

        // axios({
        //   url: url,
        //   method: "GET",
        //   responseType: "blob",
        // }).then((response) => {
        //   var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        //   var fileLink = document.createElement("a");
        //   let file_name = data.title.split(".")[0];
        //   fileLink.href = fileURL;
        //   fileLink.setAttribute("download", file_name + ".pdf");
        //   document.body.appendChild(fileLink);

        //   fileLink.click();
        // });
        // window.open(url, "_blank");
      }
    },
    async onDownload(data) {
      if (!this.checkPermissionByPermissionsName("downloadRequestDocument")) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied for download request document.Please contact owner.",
        });
      } else {
        const a = document.createElement("a");
        const content = data.parsed_data;
        const contentType = "text/plain";
        const fileName = "parsed-data.json";
        const file = new Blob([content], { type: contentType });
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
      }
    },
    getdoument(doc) {
      let tousers = "";
      let total_sign_users = 0;
      let completed_sign_users = 0;
      doc.document_users.forEach((user) => {
        if (user.fields_required || user.has_approval_access) {
          total_sign_users++;

          if (user.document_filling_status) {
            completed_sign_users++;
          }
        }
      });
      tousers += completed_sign_users + "/" + total_sign_users;
      return tousers;
    },

    format(percentage) {
      let per = percentage === 100 ? "" : `` || percentage != 100 ? "" : ``;
      console.log(per);
      let doc = "";
      return doc;
    },

    getDocumentUsers(doc) {
      let users = "";
      let last_user = doc.document_users.length - 1;
      let isDocOwner = false;
      doc.document_users.forEach((user) => {
        if (
          user &&
          user.email &&
          this.getAuthenticatedUser &&
          this.getAuthenticatedUser.email &&
          user.email == this.getAuthenticatedUser.email &&
          user.type == "SENDER"
        ) {
          isDocOwner = true;
        }
      });
      if (isDocOwner) {
        users += "To: ";
        doc.document_users.forEach((user, i) => {
          if (user.type == "RECEIVER") {
            let sep = ",";
            if (i == last_user) {
              sep = " ";
            }
            if (!user.first_name) {
              users = "";
              users += "Users not assigned";
            } else if (user.company_name && user.type == "RECEIVER") {
              users +=
                user.first_name +
                " " +
                user.last_name +
                ", " +
                "Company:" +
                user.company_name;
            } else {
              users += user.first_name + " " + user.last_name + sep;
            }
          } else if (doc.document_users.length == 1) {
            users += "Only me";
          }
        });
      } else {
        users += "From: ";
        doc.document_users.forEach((user) => {
          if (user.type == "SENDER") {
            if (user.company_name) {
              users +=
                user.first_name +
                " " +
                user.last_name +
                " " +
                user.company_name;
            } else {
              users += user.first_name + " " + user.last_name;
            }
          }
        });
      }
      return users;
    },

    getDocumentUsersTO(doc) {
      let tousers = "";
      let last_user = doc.document_users.length - 1;
      doc.document_users.forEach((user, i) => {
        if (user.type === "RECEIVER") {
          let sep = ",";
          if (i == last_user) {
            sep = "  ";
          }
          if (user.first_name) {
            if (
              user &&
              user.email &&
              this.getAuthenticatedUser &&
              this.getAuthenticatedUser.email &&
              user.email != this.getAuthenticatedUser.email
            ) {
              tousers += user.first_name + sep;
            }
          }
        }
      });
      return tousers;
    },

    getDocumentTitle(title) {
      if (title.length > 20) {
        return title.slice(0, 20) + "...";
      }
      return title;
    },

    async sortChange(a) {
      this.order_by = a.prop;
      this.order_type = a.order == "ascending" ? "asc" : "desc";
      this.fetchRequestDocuments();
      if (a.order == null) {
        this.order_by = null;
        this.order_type = null;
        this.fetchRequestDocuments();
      }
    },
    async fetchRequestDocuments() {
      this.authid = this.getAuthenticatedUser._id;
      try {
        this.configureLoading = true;
        let params = {
          limit: this.pageSize,
          page: this.page,
          //get_all:true
        };
        if (this.$route && this.$route.query && this.$route.query.type) {
          params.document_status = this.$route.query.type;
        }
        if (this.selectedStatus) {
          params.document_status = this.selectedStatus;
        }
        if (this.search_string) {
          params.search_string = this.search_string;
        }
        if (
          this.$route &&
          this.$route.query &&
          this.$route.query.type &&
          this.$route.query.type == "company-documents"
        ) {
          if (this.selected_company_user) {
            params.selected_company_user = this.selected_company_user;
          }
        }

        if (this.order_by) {
          params.order_by = this.order_by;
        }
        if (this.order_type) {
          params.order_type = this.order_type;
        }
        if (this.$route && this.$route.query && this.$route.query.type) {
          params.document_status = this.$route.query.type;
        }
        params.card_type = this.$route.params.card_type;
        console.log(">>> fetchRequestDocuments() called!");
        console.log(">>> fetchRequestDocuments()-> params: ", params);
        await this.$store.dispatch(
          "requestDocuments/fetchAllRequestDocuments",
          params
        );
        // if(this.$route && this.$route.query && this.$route.query.type && this.$route.query.type == 'company-documents'){
        //   params.company_id = this.getActiveWorkspace && this.getActiveWorkspace.company_id
        //   await this.$store.dispatch("requestDocuments/fetchAllCompanyUsers",params)
        // }
        this.currentPage = this.page;
        this.documents = this.getAllDocuments.data;
        console.log("documents", this.getAllDocuments.data);
        this.total = this.getAllDocuments.total;
        console.log(
          ">>> fetchRequestDocuments(), this.getAllDocuments.total",
          this.getAllDocuments.total
        );
        console.log(">>> fetchRequestDocuments(), this.total", this.total);
        this.configureLoading = false;
      } catch (err) {
        this.configureLoading = false;
      }
    },
    updatedDateSort: function (a, b) {
      if (a.updated_at < b.updated_at) return -1;
      if (a.updated_at > b.updated_at) return 1;
      return 0;
    },
    createdDateSort: function (a, b) {
      if (a.created_at < b.created_at) return -1;
      if (a.created_at > b.created_at) return 1;
      return 0;
    },
    onEdit(document) {
      this.$router.push({
        name: "request-documents-edit",
        params: {
          request_document_id: document._id,
        },
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.fetchRequestDocuments();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.fetchRequestDocuments();
    },
    async loadData(data) {
      if (data && data.type != "init") {
        this.pageSize = data.pageSize;
        this.page = data.page;
        await this.fetchRequestDocuments();
      }
    },
    openNameModal(id, name, action) {
      try {
        this.$store.commit(
          "requestDocuments/setDuplicateDocumentErrors",
          null,
          {
            root: true,
          }
        );
        this.modalData.document_id = id;
        this.modalData.name = name;
        this.modalData.action = action;
        this.documentActionModal = true;
      } catch (err) {
        console.log(err);
      }
    },
    handleDocAction() {
      if (this.modalData.action == "duplicate") {
        this.duplicateDocument();
        this.$emit("reloadDashboardStats", true);
      } else {
        this.renameDocument();
      }
    },
    async renameDocument() {
      this.modalLoading = true;
      await this.$store.dispatch(
        "requestDocuments/renameDocument",
        this.modalData
      );
      this.modalLoading = false;
      if (this.getRenameDocumentStatus) {
        successNotification("Document title updated successfully");
        this.documentActionModal = false;
        this.fetchRequestDocuments();
      } else {
        errorNotification("Error in updating document title");
      }
    },

    async settings(_id) {
      this.docSettingsVisible = true;
      this.configurableDocumentId = _id;
    },
    async updateDocumentStatus(document_id, document_status) {
      this.loading = true;

      let formData = new FormData();
      // this.currentImage = this.$refs.file.files.item(0);
      console.log(
        ">>> updateDocumentStatus(), document_status",
        document_status
      );
      formData.append("document_status", document_status);

      const response = await axios.post(
        `request-documents/${document_id}/update-document-status`,
        formData
      );

      // await this.$store.dispatch("requestDocuments/updateDocumentStatus", {
      //   document_id,
      //   document_status
      // });
      this.loading = false;

      console.log(">>> updateDocumentStatus(), response", response);

      if (response.data.success) {
        this.documents = [];
        this.fetchRequestDocuments();
        successNotification("Status Updated Successfully");
        // this.$emit("reloadDashboardStats", true);
      } else {
        errorNotification("Error in update document status");
      }
    },

    // async permanentDocument(document_id) {
    //   this.$confirm(
    //     "Are you sure Want To Permanently Delete.Complete Data Of This Document ?"
    //   )
    //     .then(async () => {
    //       this.loading = true;
    //       await this.$store.dispatch("requestDocuments/parmanentdelete", {
    //         document_id,
    //       });
    //       this.loading = false;
    //       if (this.getArchiveDocumentStatus) {
    //         successNotification("Document Deleted Permanently");
    //         this.$emit("reloadDashboardStats", true);
    //         this.fetchRequestDocuments();
    //       } else {
    //         errorNotification("Error in Permanent document");
    //       }
    //     })
    //     .catch(() => {});
    // },

    // async archiveDocumentundo(document_id) {
    //   this.$confirm("Are you sure to Undo the document?")
    //     .then(async () => {
    //       this.loading = true;
    //       await this.$store.dispatch("requestDocuments/undoDocument", {
    //         document_id,
    //       });
    //       this.loading = false;
    //       if (this.getArchiveDocumentStatus) {
    //         successNotification("Document Undo successfully");
    //         this.$emit("reloadDashboardStats", true);
    //         this.fetchRequestDocuments();
    //       } else {
    //         errorNotification("Error in Undo document");
    //       }
    //     })
    //     .catch(() => {});
    // },

    async archiveDocument(doc, document_id) {
      if (
        doc &&
        doc.created_by &&
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser._id &&
        doc.created_by != this.getAuthenticatedUser._id
      ) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied for delete request document.Please contact owner.",
        });
      } else {
        this.$confirm("Are you sure to delete the document?")
          .then(async () => {
            this.is_documentDelete = true;
            this.configureLoading = true;
            // await this.$store.dispatch("requestDocuments/archiveDocument", {
            //   document_id,
            // });
            const response = await axios.delete(
              `request-documents/${document_id}`
            );

            this.loading = false;
            if (response.data.success) {
              this.documents = [];
              this.fetchRequestDocuments();
              successNotification("Document deleted successfully");
              // this.$emit("reloadDashboardStats", true);
            } else {
              errorNotification("Error in deleting document");
            }
          })
          .catch(() => {});
      }
    },
  },
  watch: {
    selected_document() {
      this.$emit("deleteEvent", {
        data: this.selected_document,
      });
    },
    selectedDocStatus: function (val) {
      if (val !== this.selectedStatus) {
        this.page = 1;
        this.selectedStatus = val;
        console.log(">>> selectedDocStatus() called, val", val);
        console.log(">>> this.$route.query: ", this.$route.query);
        if (
          this.$route &&
          this.$route.query &&
          this.$route.query.type != this.selectedStatus
        ) {
          let routeQuery = { type: this.selectedStatus };
          this.$router.replace({ query: routeQuery });
        }
        this.fetchRequestDocuments();
      }
    },
    search_string: function (val) {
      this.search_string = val;
      this.fetchRequestDocuments();
    },
    selected_company_user: function (val) {
      this.selected_company_user = val;
      this.fetchRequestDocuments();
    },
  },
  beforeDestroy() {
    this.$store.commit("requestDocuments/setAllDocuments", null, {
      root: true,
    });
    // this.$store.commit("requestDocuments/setRenameDocumentStatus", null, {
    //   root: true,
    // });
    // this.$store.commit("requestDocuments/setDuplicateDocumentStatus", null, {
    //   root: true,
    // });
    // this.$store.commit("requestDocuments/setArchiveDocumentStatus", null, {
    //   root: true,
    // });
    // this.$store.commit("requestDocuments/setDuplicateDocumentErrors", null, {
    //   root: true,
    // });
    // this.$store.commit("requestDocuments/setDocumentVoidStatus", null, { root: true });
    // this.$store.commit("requestDocuments/setResendDocumentStatus", null, {
    //   root: true,
    // });
    // this.$store.commit("requestDocuments/setDocumentSaveAsTemplate", null, {
    //   root: true,
    // });
    // this.$store.commit("requestDocuments/setDocumentSaveAsTemplate", null, {
    //   root: true,
    // });
    // this.$store.commit("requestDocuments/setDocumentCorrectStatus", null, {
    //   root: true,
    // });
    // this.$store.commit("requestDocuments/setDocumentCorrectErrors", null, {
    //   root: true,
    // });
    // this.$store.commit("requestDocuments/setCloneVoidDocStatus", null, { root: true });
    // this.$store.commit("requestDocuments/setCloneVoidDocData", null, { root: true });
    // this.$store.commit("requestDocuments/setUpdateDocumentUsersStatus", null, {
    //   root: true,
    // });
    // this.$store.commit("requestDocuments/setDocumentSaveAsTemplateData", null, {
    //   root: true,
    // });
    // this.$store.commit("requestDocuments/setSendCompletedDocumentStatus", null, {
    //   root: true,
    // });
  },
};
</script>

<style lang="scss" scoped>
.text-muted {
  white-space: nowrap;
  font-size: 14px;
}

.all-documents-view {
  .title {
    letter-spacing: 0.019em;
  }
  .text-secondary-sent {
    font-size: 10px !important;
    font-weight: 400 !important;
    line-height: 14px !important;
    text-align: left !important;
    color: #439ffc !important;
  }
  .text-secondary {
    font-size: 14px !important;
    font-weight: 400 !important;
    // line-height: 14px !important;
    text-align: left !important;
    color: #6c757d !important;
  }
  .doc-subs {
    font-size: 10px;
    margin-top: -13px;
    font-weight: 300;
    text-align: right;
    margin-right: 9%;
  }
  .doc-sub {
    font-size: 10px;
    font-weight: 300;
    line-height: 14px;
    //margin-bottom:-18px;
    text-align: left;
  }
  .doc-title {
    cursor: pointer;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    font-weight: 300;
    line-height: 14px;
    text-align: left;

    color: #6c757d !important;
  }
  .check-font {
    color: #6c757d !important;
    font-family: "Inter", sans-serif;
  }
  .circle {
    position: relative;
    padding-left: 20px;
    text-transform: capitalize;
    font-size: 14px;
    &::after {
      background: #635e5e;
      content: "";
      position: absolute;
      top: 4px;
      left: 0;
      height: 10px;
      width: 10px;
      display: inline-block;
      border-radius: 50%;
    }
    &.active::after {
      background: #08ad36;
    }
    &.inactive::after {
      background: #f754a2;
    }
  }
  .custom-pagination {
    text-align: center;
    margin-top: 5px;
  }
}
.main-image {
  .box {
    margin-left: 25%;
  }
}
.sub-image {
  width: 75px;
}
.main_container {
  width: 100vw;
  height: 100vh;
}
.box {
  margin: 20px;
  text-align: center;
  box-sizing: border-box;
  font-size: 10px;
  margin-top: 30px;
}
.outersize {
  height: 100vh;
  @media (max-width: 991.98px) {
    width: 90%;
    left: 5%;
  }
  @media (min-width: 992px) {
    width: 50%;
    left: 25%;
  }
}
.numbers {
  position: absolute;
  margin-left: -25px;
}
.arrows {
  position: absolute;
  margin-left: 120px;
  margin-top: 50px;
}
.center {
  background: #1b487e;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  align-items: center;
  text-align: center;
  margin-left: 15px;
}
.put_numbers {
  position: relative;
  top: 2px;
  color: white;
  margin: auto;
  margin-top: 20px !important;
}

.dialog-footer {
  margin-left: -20%;
  margin-top: -140px;
}

.dialog-footer {
  margin-left: 10px;
}
#bsty {
  width: fit-content;
  background-color: #f562b3;
  border: none;
  color: white;
  text-align: center;
}
.dilog-header {
  text-align: center;
}
.headp {
  // margin-top: -35px;
  font-size: 24px;
  color: black;
}
.footer-dialog {
  text-align: center;
  margin-top: 0px;
}
.mailboxStyle {
  margin-left: 65%;
}
// .head1p {
//   margin-top: -20px;
// }
@media screen and (max-width: 1200px) {
  .box {
    width: 40%;
    height: 40%;
  }
}
</style>
